export function getValidFilters(
  filters: Record<string, string | null | undefined>
): Record<string, string> {
  const validFilters: Record<string, any> = Object.fromEntries(
    Object.entries(filters).filter(
      ([key, value]) => value !== null && value !== undefined
    )
  )
  return validFilters
}
