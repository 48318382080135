import { isProductionEnv } from 'shared/envHelpers'

export const siteBaseUrl = isProductionEnv()
  ? 'https://www.handball.net'
  : 'https://www.handball.madebytickaroo.com'

export const baseImagePath = siteBaseUrl

// allow overriding in env.local
export const apiBaseUrl = process.env.API_BASE_URL
  ? process.env.API_BASE_URL
  : `${siteBaseUrl}/a`
