module.exports = {
  germanRoutes: {
    daySchedule: 'spiele-des-tages',
    scheduleSearch: 'spielplansuche',

    leagues: 'ligen',
    tournaments: 'wettbewerbe',
    table: 'tabelle',
    statistics: 'statistiken',
    schedule: 'spielplan',
    teamLeagueSchedule: 'liga-spielplan',
    allGames: 'alle-spiele',
    rounds: 'spieltage',
    games: 'spiele',
    clubs: 'vereine',
    teams: 'mannschaften',
    news: 'news',
    newsletter: 'newsletter',
    squad: 'kader',
    organizations: 'verbaende',
    info: 'info',
    memberClubs: 'stammvereine',
    parentClubs: 'spielgemeinschaften',
    nutritionBlog: 'ernaehrungs-blog',
    healthBlog: 'gesundheitsblog',
    imprint: 'impressum',
    contact: 'kontakt',
    privacyPolicy: 'datenschutz',
    termsOfUse: 'nutzungsbedingungen',
    faq: 'faq',
    contentNotAvailable: 'inhalt-nicht-verfugbar',
    fields: 'hallen',
    search: 'suche',
    login: 'anmelden',
    lostPassword: 'passwort-vergessen',
    lostPasswordUpdate: 'passwort-zuruecksetzen',
    myProfile: 'mein-profil',
    editProfile: 'profil-bearbeiten',
    signup: 'registrieren',
    emailConfirmation: 'email-bestaetigen',
    whySignUp: 'warum-registrieren',
    widgets: 'widgets',
    videocenter: 'videocenter',
    press: 'press',
    tags: 'tags',
    fanshops: 'fanshops',
    ticketshops: 'ticketshops',
    live: 'live',
    write: 'schreiben',
    presstext: 'spielbericht'
  },

  projectRoutes: {
    daySchedule: 'day-schedule',
    scheduleSearch: 'schedule-search',

    leagues: 'leagues',
    tournaments: 'tournaments',
    table: 'table',
    statistics: 'statistics',
    schedule: 'schedule',
    teamleagueSchedule: 'league-schedule',
    allGames: 'all-games',
    rounds: 'rounds',
    games: 'games',
    clubs: 'clubs',
    teams: 'teams',
    news: 'news',
    newsletter: 'newsletter',
    squad: 'squad',
    organizations: 'organizations',
    info: 'info',
    memberClubs: 'member-clubs',
    parentClubs: 'parent-clubs',
    nutritionBlog: 'nutrition-blog',
    healthBlog: 'health-blog',
    imprint: 'imprint',
    contact: 'contact',
    privacyPolicy: 'privacy-policy',
    termsOfUse: 'terms-of-use',
    faq: 'faq',
    contentNotAvailable: 'content-not-available',
    fields: 'fields',
    search: 'search',
    login: 'login',
    lostPassword: 'lost-password',
    lostPasswordUpdate: 'lost-password-update',
    myProfile: 'my-profile',
    editProfile: 'edit-profile',
    signup: 'signup',
    emailConfirmation: 'email-confirmation',
    whySignUp: 'why-sign-up',
    widgets: 'widgets',
    videocenter: 'videocenter',
    press: 'press',
    tags: 'tags',
    fanshops: 'fanshops',
    ticketshops: 'ticketshops',
    live: 'live',
    write: 'write',
    presstext: 'presstext'
  }
}
